const appVars = require('../server/api/config').default
const headlessAuthData = {
  user: 'rest_api_aqua',
  pass: `${process.env.AQ_HEADLESS_API_PASS}`
}
export const state = () => ({})

export const mutations = {}

export const actions = {
  async nuxtServerInit (context) {
    let headlessToken = ''
    headlessToken = this.$cookies.get('headless-token')

    if (headlessToken === undefined || headlessToken !== '') {
      await getAndSetNewToken(this)
    } else {
      // Verify that token is still valid
      try {
        let { data } = await this.$axios.post(`${appVars.apiUrlBase}jwt-auth/v1/token/validate`, {}, { 'headers': { 'Authorization': 'Bearer ' + headlessToken } })
        // Test response
        if (data.status !== 200) {
          await getAndSetNewToken(this)
        } else {
          // Token valid
          // Set the Token on the Axios module
          context.$axios.setToken(data.token, 'Bearer')
        }
      } catch (e) {
        console.log(e)
      }
    }

    let [pages, posts, products] = await Promise.all([
      this.$axios.get('/api/pages').catch(error => error),
      this.$axios.get('/api/posts').catch(error => error),
      this.$axios.get('/api/products').catch(error => error)
    ])

    if (pages && pages.data) {
      context.commit('pages/SAVE_PAGES', pages.data, { root: true })
    }
    if (posts && posts.data) {
      context.commit('posts/SAVE_POSTS', posts.data, { root: true })
    }
    if (products) {
      context.commit('products/SAVE_PRODUCTS', products.data, { root: true })
    }
  }
}

export const getters = {}

const getAndSetNewToken = async (context) => {
  return new Promise(async function (resolve, reject) {
    // Get a new token from the headless
    try {
      let { data } = await context.$axios.post(`${appVars.apiUrlBase}jwt-auth/v1/token?username=${headlessAuthData.user}&password=${headlessAuthData.pass}`)
      if (data) {
        context.$cookies.set('headless-token', data.token)
        // Set the Token on the Axios module
        context.$axios.setToken(data.token, 'Bearer')
        // Save the token to the store, since the above is fucked
        context.commit('token/SAVE_TOKEN', data.token)
        resolve()
      }
    } catch (e) {
      reject(e)
    }
  })
}
