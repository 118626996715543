<template>
  <div id="wrapper">
    <main-header/>
    <nuxt/>
    <main-footer/>
  </div>
</template>

<script>
import MainHeader from '~/components/Header.vue'
import MainFooter from '~/components/Footer.vue'

export default {
  components: {
    MainHeader,
    MainFooter
  }
}
</script>

<style lang="scss" scoped>
/* === Wrapper === */
#wrapper {
  background: #fff;
  box-shadow: 0px 10px 27px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1300px;
  min-height: 100vh;
}
</style>
