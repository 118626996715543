export const state = () => ({
  products: []
})

export const mutations = {
  SAVE_PRODUCTS (state, products) {
    state.products = products
  }
}

export const actions = {}

export const getters = {
  products: state => state.products,
  systems: state => state.products.filter(product => typeof product === 'object' && product.product_type[0] && product.product_type[0].slug === 'system' ? product : undefined),
  currentSystem: (state) => (slug) => {
    if (state.products.length === 0) return undefined
    return state.products.find(product => product.custom_fields.frontend_slug === slug)
  },
  accessories: state => state.products.filter(product => typeof product === 'object' && product.product_type[0] && product.product_type[0].slug === 'accessory' ? product : undefined),
  parentImage: (state) => (id) => {
    const parentObject = state.products.find(product => product.ID === id)
    return parentObject.custom_fields.product_image
  },
  parentName: (state) => (id) => {
    const parentObject = state.products.find(product => product.ID === id)
    return parentObject.custom_fields.product_name
  },
  parentProduct: (state) => (id) => state.products.find(product => product.ID === id),
  variations: (state) => (id) => {
    const parentObject = state.products.find(product => product.ID === id)

    // Place parentID back into the variations
    parentObject.custom_fields.variations.forEach(function (obj) {
      obj.parentID = id
      obj.quantity = 1
    })
    return parentObject.custom_fields.variations
  },
  variation: (state) => (productId, sku) => {
    const product = state.products.find(product => product.ID === productId)
    if (!product || !product.custom_fields || !product.custom_fields.variations) return null
    return product.custom_fields.variations.find(product => product.part_number === sku)
  }
}
