import { find } from 'lodash'

export const state = () => ({
  pages: []
})

export const mutations = {
  SAVE_PAGES (state, pages) {
    state.pages = pages
  }
}

export const getters = {
  pages: state => state.pages,
  pageIdFromSlug: (state) => (slug) => {
    const pageObject = find(state.pages, { slug: slug })
    return pageObject.id
  },
  doesPageExist: state => (slug) => state.pages.find((page) => {
    if (page.slug === slug) return true
    return false
  })
}
