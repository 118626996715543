import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3693a6d1 = () => interopDefault(import('../pages/accessories/index.vue' /* webpackChunkName: "pages/accessories/index" */))
const _56dc0677 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _5eef70dc = () => interopDefault(import('../pages/Cart.vue' /* webpackChunkName: "pages/Cart" */))
const _5e5719b8 = () => interopDefault(import('../pages/Checkout.vue' /* webpackChunkName: "pages/Checkout" */))
const _33d5eebe = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _7bec30b9 = () => interopDefault(import('../pages/products/index.vue' /* webpackChunkName: "pages/products/index" */))
const _976e26aa = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _773d337e = () => interopDefault(import('../pages/warranty-policy.vue' /* webpackChunkName: "pages/warranty-policy" */))
const _96864df0 = () => interopDefault(import('../pages/warranty-registration.vue' /* webpackChunkName: "pages/warranty-registration" */))
const _34dd0589 = () => interopDefault(import('../pages/accessories/_slug.vue' /* webpackChunkName: "pages/accessories/_slug" */))
const _5525652f = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _24b0dd8a = () => interopDefault(import('../pages/blog/_slug.vue/index.vue' /* webpackChunkName: "pages/blog/_slug.vue/index" */))
const _a45a6f06 = () => interopDefault(import('../pages/systems/_slug.vue' /* webpackChunkName: "pages/systems/_slug" */))
const _e24ad820 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _55c9db2b = () => interopDefault(import('../pages/_slug/index.vue' /* webpackChunkName: "pages/_slug/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/accessories",
    component: _3693a6d1,
    name: "accessories"
  }, {
    path: "/blog",
    component: _56dc0677,
    name: "blog"
  }, {
    path: "/Cart",
    component: _5eef70dc,
    name: "Cart"
  }, {
    path: "/Checkout",
    component: _5e5719b8,
    name: "Checkout"
  }, {
    path: "/contact",
    component: _33d5eebe,
    name: "contact"
  }, {
    path: "/products",
    component: _7bec30b9,
    name: "products"
  }, {
    path: "/reset-password",
    component: _976e26aa,
    name: "reset-password"
  }, {
    path: "/warranty-policy",
    component: _773d337e,
    name: "warranty-policy"
  }, {
    path: "/warranty-registration",
    component: _96864df0,
    name: "warranty-registration"
  }, {
    path: "/accessories/:slug",
    component: _34dd0589,
    name: "accessories-slug"
  }, {
    path: "/blog/:slug",
    component: _5525652f,
    name: "blog-slug"
  }, {
    path: "/blog/:slug.vue",
    component: _24b0dd8a,
    name: "blog-slug.vue"
  }, {
    path: "/systems/:slug?",
    component: _a45a6f06,
    name: "systems-slug"
  }, {
    path: "/",
    component: _e24ad820,
    name: "index"
  }, {
    path: "/:slug",
    component: _55c9db2b,
    name: "slug"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
