import Vue from 'vue'

export const state = () => ({
  cart: [],
  shippingMethods: [],
  selectedShippingMethod: '',
  taxRate: 0,
  discount: {
    couponType: 'dollar',
    discount_percent: 0,
    base_discount_amount: 0
  }
})

export const mutations = {
  ADD_TO_CART (state, payload) {
    // Loop over the items in the cart and locate a record
    const record = state.cart.find(product => product.part_number === payload.part_number)
    // If no record is found add the item to the cart
    if (!record) {
      state.cart.push({
        ...payload,
        quantity: 1
      })
      // Update the Cart Totals
    } else {
      // Otherwise we add to the quantity
      record.quantity++
      // Update the Cart Totals
    }
  },
  REMOVE_FROM_CART (state, index) {
    state.cart.splice(index, 1)
  },
  CHANGE_SIZE (state, { index, payload }) {
    // Replace the variation in the cart
    state.cart.splice(index, 1, payload)
  },
  INCREMENT (state, index) {
    let newTotal = ++state.cart[index].quantity
    Vue.set(state.cart[index], 'quantity', newTotal)
    // state.cart[index].quantity++
  },
  DECREMENT (state, index) {
    let newTotal = --state.cart[index].quantity
    Vue.set(state.cart[index], 'quantity', newTotal)
    // Vue.set(state.cart[index], 'quantity', state.cart[index].quantity--)
    // state.cart[index].quantity--
  },
  STORE_SHIPPING_METHODS (state, payload) {
    state.shippingMethods = payload
  },
  UPDATE_SELECTED_SHIPPING_METHOD (state, payload) {
    state.selectedShippingMethod = payload
  },
  STORE_TAX_RATE (state, payload) {
    state.taxRate = payload
  },
  CLEAR_CART (state) {
    state.cart = []
    state.shippingMethods = []
    state.selectedShippingMethod = ''
    state.taxRate = 0
    state.discount = {
      couponType: 'dollar',
      discount_percent: 0,
      base_discount_amount: 0
    }
  },
  ADD_DISCOUNT (state, discountAmount) {
    state.discount = discountAmount
  }
}

export const actions = {
  getShippingMethods ({ commit, rootGetters }) {
    this.$axios.get('/api/shippingMethods/')
      .then(function (response) {
        commit('STORE_SHIPPING_METHODS', response.data)
      })
      .catch(function (error) {
        console.log(error)
      })
  },
  getTaxRate ({ commit, rootGetters }, postcode) {
    this.$axios.get('/api/taxRate/' + postcode)
      .then(function (response) {
        var taxRateString = parseFloat(response.data.rate)
        var taxNumber = taxRateString.toFixed(2)
        if (isNaN(taxNumber)) {
          commit('STORE_TAX_RATE', 0)
        } else {
          commit('STORE_TAX_RATE', Number(taxNumber))
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }
}

export const getters = {
  cart: state => state.cart,
  selectedShippingMethodPrice: (state) => {
    if (state.selectedShippingMethod !== '') {
      // Get selected shipping method price
      var shippingMethodPrice = state.shippingMethods.filter(function (shippingMethod) {
        return shippingMethod.id === state.selectedShippingMethod
      })[0]

      return Number(shippingMethodPrice.amount)
    }

    return 0
  },
  itemsInCart: (state) => {
    return state.cart.reduce((quantity, item) => {
      return quantity + item.quantity
    }, 0)
  },
  magentoItems: (state) => {
    let cleanedItems = []

    state.cart.forEach((item) => {
      let cleanedItem = {}
      cleanedItem.parentID = item.parentID
      cleanedItem.sku = item.part_number
      cleanedItem.price = item.price
      cleanedItem.qty = item.quantity

      cleanedItems.push(cleanedItem)
    })

    return cleanedItems
  },
  cartSubTotal: (state) => {
    let total = 0

    state.cart.forEach((item) => {
      // Check if there is a discount applied
      total = total + (item.quantity * Number(item.price))
    })
    return Number(total.toFixed(2))
  },
  taxCost: (state, getters) => {
    if (state.taxRate === 0) return 0
    let total = 0
    let cartWithDiscount

    // Calculate discount based on coupon_type
    if (state.discount.coupon_type === 'percentage') {
      const discountAmount = getters.cartSubTotal * (state.discount.discount_percent / 100).toFixed(4)
      cartWithDiscount = getters.cartSubTotal - discountAmount
    } else {
      cartWithDiscount = getters.cartSubTotal + state.discount.base_discount_amount
    }

    total = (getters.cartSubTotal !== 0 ? cartWithDiscount * getters.taxRate : 0)
    return Number(total.toFixed(2))
  },
  cartTotal: (state, getters) => {
    let total = 0

    // Get discount amount based on couon type
    if (state.discount && state.discount.coupon_type === 'percentage') {
      const discountAmount = getters.cartSubTotal * (state.discount.discount_percent / 100).toFixed(4)
      let cartWithDiscount = getters.cartSubTotal - discountAmount

      total = (cartWithDiscount + getters.selectedShippingMethodPrice) + getters.taxCost
    } else {
      total = (getters.cartSubTotal + getters.selectedShippingMethodPrice + getters.taxCost)
    }

    return Number(total.toFixed(2))
  },
  shippingMethods: (state) => {
    return state.shippingMethods
  },
  taxRate: (state) => {
    return Number(state.taxRate / 100)
  },
  discount: (state) => {
    if (state.discount && state.discount.coupon_type === 'percentage') {
      return state.discount
    } else {
      return state.discount
    }
  }
}
