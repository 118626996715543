<template>
  <div>
    <nav id="mobile-nav">
      <ul class="nav">
        <li class="nav-item mobile-nav-item">
          <nuxt-link
            to="/cart"
            class="cart-icon">
            <span class="icon-cart">
              <span
                v-show="itemsInCart > 0"
                class="cart-status">{{ itemsInCart }}</span>
            </span>
          </nuxt-link>
        </li>
        <li
          class="nav-item mobile-nav-item"
          @click="toggleNav">
          <div id="nav-icon4">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </li>
      </ul>
    </nav>

    <nav id="main-nav">
      <div class="menu-primary-container">
        <ul
          id="primary-menu"
          class="nav"
          @click="removeMenu">
          <li class="nav-item menu-item">
            <nuxt-link to="/">Home</nuxt-link>
          </li>
          <li class="nav-item menu-item">
            <nuxt-link to="/products">Products</nuxt-link>
          </li>
          <li class="nav-item menu-item">
            <nuxt-link to="/accessories">Accessories</nuxt-link>
          </li>
          <li class="nav-item menu-item">
            <a href="https://help.aquabedwarmer.com/s/">Support</a>
          </li>
          <li class="nav-item menu-item menu-item-cart">
            <nuxt-link
              to="/cart"
              class="cart-icon">
              <span class="icon-cart">
                <span
                  v-show="itemsInCart > 0"
                  class="cart-status">{{ itemsInCart }}</span>
              </span>
            </nuxt-link>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'MainNav',
  data: () => ({
    previousCartCount: 0,
    cartItemsIncreased: false
  }),
  computed: {
    ...mapGetters('cart', {
      itemsInCart: 'itemsInCart'
    })
  },
  methods: {
    toggleNav (e) {
      e.stopPropagation()

      // Main menu mobile nav
      const mobileMenu = document.querySelector('#main-nav')
      const navIcon = document.querySelector('#nav-icon4')

      mobileMenu.classList.toggle('active')
      navIcon.classList.toggle('open')

      if (mobileMenu.classList[0] === 'active') {
        document.querySelector('#wrapper').addEventListener('click', function () {
          mobileMenu.classList.remove('active')
          navIcon.classList.remove('open')
        })
      }

      document.querySelector('#wrapper').removeEventListener('click', function () {
        mobileMenu.classList.remove('active')
        navIcon.classList.remove('open')
      })
    },
    removeMenu () {
      const mobileMenu = document.querySelector('#main-nav')
      const navIcon = document.querySelector('#nav-icon4')

      mobileMenu.classList.remove('active')
      navIcon.classList.remove('open')
    },
    animateIcon () {
      const cartIcon = document.querySelector('.cart-status')

      // Add animation class
      cartIcon.classList.add('pop-animation')

      // Wait till animation has finished and remove class
    }
  }
}
</script>

<style lang="scss" scoped>
#nav-icon1,
#nav-icon2,
#nav-icon3,
#nav-icon4 {
  width: 30px;
  height: 25px;
  position: relative;
  margin: 0 auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

#nav-icon1 span,
#nav-icon3 span,
#nav-icon4 span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: $main-font-color;
  border-radius: 4px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

/* Icon 4 */

#nav-icon4 span:nth-child(1) {
  top: 0px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon4 span:nth-child(2) {
  top: 10px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon4 span:nth-child(3) {
  top: 20px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon4.open span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: -1px;
  left: 0px;
}

#nav-icon4.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

#nav-icon4.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 20px;
  left: 0px;
}
.pop-animation {
  animation: pop 0.3s linear 1;
}
@keyframes pop {
  50% {
    transform: scale(1.2);
  }
}
</style>
