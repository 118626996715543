<template>
  <div class="centered-content">
    <nuxt/>
  </div>
</template>

<script>

export default {
}
</script>

<style lang="scss" scoped>
/* === Wrapper === */
.centered-content {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
