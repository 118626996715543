export const state = () => ({
  token: ''
})

export const mutations = {
  SAVE_TOKEN (state, payload) {
    state.token = payload
  }
}

export const getters = {
  getToken: state => {
    return state.token
  }
}
