<template>
  <div class="footer-wrapper">
    <section id="newsletter-section">
      <div class="newsletter-section__row">
        <h3>Sign up to receive the latest news from Aqua Bed Warmer!</h3>

        <div
          id="mc_embed_signup"
          class="newsletter-section__form">
          <input
            v-model="mcEmail"
            type="email"
            value
            class="required email"
            placeholder="Email Address"
            @keyup.enter="newsletterSignup"
          >
          <button
            id="mc-embedded-subscribe"
            type="submit"
            value="Subscribe"
            name="subscribe"
            class="button more-info-button"
            @click="newsletterSignup"
          >Sign Up</button>
        </div>
      </div>
    </section>

    <section id="social-media-section">
      <div class="container">
        <div class="row">
          <div class="twelve columns">
            <div class="social-container">
              <a
                href="https://www.facebook.com/aquabedwarmer"
                target="_blank"
                rel="noopener">
                <img
                  src="/img/facebook_logo.png"
                  alt="Aqua bed warmer Facebook">
                <p class="no-show-mobile">
                  Like us
                  <span class="no-show-tablet">on Facebook</span>
                </p>
              </a>
            </div>

            <div class="social-container">
              <a
                href="https://twitter.com/aquabedwarmer"
                target="_blank"
                rel="noopener">
                <img
                  src="/img/twitter_logo.png"
                  alt="Aqua bed warmer Twitter">
                <p class="no-show-mobile">
                  Follow us
                  <span class="no-show-tablet">on Twitter</span>
                </p>
              </a>
            </div>

            <div class="social-container">
              <a
                href="https://www.instagram.com/aquabedwarmer/"
                target="_blank"
                rel="noopener">
                <img
                  src="/img/instagram_logo.png"
                  alt="Aqua bed warmer Instagram">
                <p class="no-show-mobile">
                  Follow us
                  <span class="no-show-tablet">on Instagram</span>
                </p>
              </a>
            </div>

            <div class="social-container">
              <a
                href="https://www.youtube.com/user/AquaBedWarmer"
                target="_blank"
                rel="noopener">
                <img
                  src="/img/youtube_logo.png"
                  alt="Aqua bed warmer Youtube">
                <p class="no-show-mobile">
                  Subscribe
                  <span class="no-show-tablet">to our YouTube</span>
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <footer id="main-footer">
      <div class="container main-footer__container">
        <div class="row flex-parent">
          <!-- Copyright info
          ==================================================-->
          <div class="four columns footer-copyright">
            <p>
              &copy;{{ currentYear }} Firstech, LLC. All Rights Reserved
              <br>
              <nuxt-link to="/privacy-policy">Privacy Policy</nuxt-link>
            </p>
          </div>

          <!-- Footer navigation
          ==================================================-->
          <div class="eight columns footer-nav-container">
            <nav class="footer-nav">
              <ul>
                <li>
                  <nuxt-link to="/products">Products</nuxt-link>
                </li>
                <li>
                  <nuxt-link to="/accessories">Accessories</nuxt-link>
                </li>
                <!-- <li><nuxt-link to="/support">Support</nuxt-link></li> -->
                <li>
                  <nuxt-link to="/contact">Contact</nuxt-link>
                </li>
                <li>
                  <nuxt-link to="/warranty-registration">Warranty Registration</nuxt-link>
                </li>
                <li>
                  <nuxt-link to="/warranty-policy">Warranty Terms</nuxt-link>
                </li>
                <li>
                  <nuxt-link to="/blog">Blog</nuxt-link>
                </li>
                <li>
                  <a href="https://help.aquabedwarmer.com/s/">Support</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </footer>

    <no-ssr>
      <notifications
        classes="coupon-valid-notification"
        group="newsletterSuccess"
        position="top left"
      />
    </no-ssr>

    <no-ssr>
      <notifications
        classes="coupon-valid-notification"
        group="newsletterError"
        position="top left"
      />
    </no-ssr>
  </div>
</template>

<script>
export default {
  data: () => ({
    mcEmail: ''
  }),
  computed: {
    currentYear: function () {
      var today = new Date()
      var year = today.getFullYear()
      return year
    }
  },
  methods: {
    async newsletterSignup () {
      if (!this.mcEmail) {
        this.$notify({
          group: 'newsletterError',
          title: 'Invalid Email.',
          text: `Please input a valid email.`,
          type: 'error'
        })
      } else {
        try {
          let { data } = await this.$axios.post('/api/signup', { email: this.mcEmail })
          if (data.status === 400) {
            this.$notify({
              group: 'newsletterError',
              title: 'Please try again.',
              text: 'There was an error adding that email to the list.',
              type: 'error'
            })
          } else {
            this.$notify({
              group: 'newsletterSuccess',
              title: 'Email Added',
              text: `${this.mcEmail} has been added to the list.`,
              type: 'success'
            })
            // Clear input
            this.mcEmail = ''
          }
        } catch (e) {
          this.$notify({
            group: 'newsletterError',
            title: 'Please try again.',
            text: 'There was an error adding that email to the list.',
            type: 'error'
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.footer-wrapper {
  position: relative;
}

#main-footer {
  padding: 0 25px 15px 25px;

  @media screen and (max-width: 850px) {
    padding: 0 15px 10px 15px;
  }

  .main-footer__container {
    border-top: 1px solid $grey;
    padding-top: 25px;
  }

  .footer-nav {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      text-align: right;

      li {
        display: inline-block;

        a {
          display: inline-block;
          padding: 13px 25px;
          text-decoration: none;
          color: $main-font-color;
          font-weight: bold;
          font-size: 0.8em;

          @media (min-width: 461px) and (max-width: 588px) {
            padding: 10px 15px;
          }

          @media screen and (max-width: 460px) {
            padding: 10px 8px;
          }
        }

        @media screen and (max-width: 550px) {
          display: block;
        }
      }

      @media screen and (max-width: 850px) {
        text-align: center;
      }
    }
  }

  .footer-copyright {
    p {
      font-size: 0.8em;
    }
  }

  @media screen and (max-width: 850px) {
    text-align: center;
  }
}

@media screen and (max-width: 850px) {
  .flex-parent {
    display: flex;
    flex-flow: column;
  }

  .footer-nav-container {
    order: 1;
  }

  .footer-copyright {
    order: 2;
  }
}

#social-media-section {
  background: #fff;
  padding: 30px 15px 15px 15px;
  text-align: center;

  .social-container {
    display: inline-block;
    padding: 0 5%;

    &:nth-child(1) {
      padding: 0 5% 0 0;
    }

    &:nth-last-child(1) {
      padding: 0 0 0 5%;
    }

    @media (min-width: 807px) and (max-width: 1003px) {
      padding: 0 5%;

      &:nth-child(1) {
        padding: 0 5% 0 0;
      }

      &:nth-last-child(1) {
        padding: 0 0 0 5%;
      }
    }

    img {
      width: 45px;

      @media screen and (max-width: 460px) {
        width: 42px;
      }
    }

    p {
      margin-top: 0;
      font-size: 0.9em;
    }
  }

  @media screen and (max-width: 806px) {
    padding: 25px 0 20px;
  }
}

#newsletter-section {
  width: 100%;
  margin: 0 auto;
  padding: 30px 12%;
  background-color: $light-grey;

  .newsletter-section__row {
    display: flex;
    flex-direction: row;
    align-items: center;

    @media screen and (max-width: 900px) {
      flex-direction: column;

      h3 {
        margin: 0 0 15px 0;
      }
    }
  }

  h3 {
    display: inline-block;
    font-weight: bold;
    margin: 0;
    margin-right: 27px;
    font-size: 20px;
  }

  .newsletter-section__form {
    display: inline-block;
    white-space: nowrap;

    input {
      margin-right: 14px;
      margin-bottom: 0px;
      min-width: 255px;
    }

    .button {
      width: 145px;
      padding: 16px 0;
    }
  }

  @media screen and (max-width: 850px) {
    text-align: center;
    padding: 35px 3%;

    h3 {
      margin: auto;
      max-width: 330px;
    }

    .newsletter-section__form {
      display: block;
      white-space: normal;

      input {
        max-width: 460px;
        width: 60%;
        margin-right: 0;
        display: block;
        margin: 5px auto 15px;
        white-space: normal;
      }

      .button {
        display: block;
        width: 245px;
        margin: auto;
        padding: 16px 0;
      }
    }
  }
}
</style>

<style lang="scss">
// Global styles
.coupon-valid-notification {
  margin: 25px 0 0 25px;
  background-color: #57deab;
  padding: 15px 25px;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;

  .notification-title {
    font-size: 18px;
    margin-bottom: 5px;
  }

  .notification-content {
    // Style for content
  }

  &.success {
    background-color: #57deab;
  }

  &.error {
    background-color: #e15d6b;
  }
}
</style>
