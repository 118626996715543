export const state = () => ({
  posts: []
})

export const mutations = {
  SAVE_POSTS (state, posts) {
    state.posts = posts.reverse()
  }
}

export const getters = {
  posts: state => state.posts,
  currentPost: (state) => (slug) => state.posts.find(post => post.custom_fields.frontend_slug === slug),
  mainPost: state => state.posts[0],
  subPosts: (state) => {
    const flatPosts = JSON.parse(JSON.stringify(state.posts))
    // Remove first post
    flatPosts.shift()
    return flatPosts.sort((a, b) => new Date(b.post_date) - new Date(a.post_date))
  }
}
