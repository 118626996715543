<template>
  <div class="container">
    <four-oh-four />
  </div>
</template>

<script>
import FourOhFour from '~/components/404.vue'

export default {
  components: {
    FourOhFour
  },
  props: {
    error: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  layout: 'default'
}
</script>
