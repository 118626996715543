export default ({ $axios, store, route }) => {
  if (process.server) {
    return
  }
  $axios.interceptors.request.use(request => {
    // Set the baseURL for all server bound requests
    if (request.url.indexOf('/api/') === 0) {
      request.baseURL = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`
    }
    // Get token from auth.js store
    const token = store.getters['token/getToken']
    // Update token axios header
    if (token) {
      request.headers.common['Authorization'] = `Bearer ${token}`
    }
    return request
  })
}
