export const state = () => ({
  invalidCoupons: [],
  attachedCoupons: []
})

export const mutations = {
  SAVE_VALID_COUPON (state, payload) {
    state.attachedCoupons.push(payload)
  },
  SAVE_INVALID_COUPON (state, payload) {
    state.invalidCoupons.push(payload)
  },
  CLEAR_COUPONS (state) {
    state.attachedCoupons = []
    state.invalidCoupons = []
  }
}

export const actions = {
  async checkCoupon ({ commit, rootGetters }, couponCode) {
    let { data } = await this.$axios.get(`/api/promos/${couponCode}`).catch((error) => {
      console.log(error)
    })
    if (!data) {
      commit('SAVE_INVALID_COUPON', {
        base_discount_amount: 0,
        coupon_code: couponCode
      })
      return 'Coupon Invalid'
    } else {
      let parsedData = JSON.parse(data)
      parsedData.coupon_code = couponCode
      if (parsedData.items[0].discount_percent > 0) {
        // Discount is percentage based
        // Add type of coupon
        parsedData.coupon_type = 'percentage'
        parsedData.discount_percent = parsedData.items[0].discount_percent

        commit('cart/ADD_DISCOUNT', parsedData, { root: true })
        commit('SAVE_VALID_COUPON', parsedData)
      } else if (parsedData.base_discount_amount < 0) {
        // Commit discount to cart
        // Add type of coupon
        parsedData.coupon_type = 'dollar'
        parsedData.discount_percent = 0

        commit('cart/ADD_DISCOUNT', parsedData, { root: true })
        commit('SAVE_VALID_COUPON', parsedData)

        return parsedData
      } else {
        commit('SAVE_INVALID_COUPON', parsedData)
        return 'Coupon Invalid'
      }
    }
  }
}

export const getters = {
  attachedCoupons: state => state.attachedCoupons,
  invalidCoupons: state => state.invalidCoupons
}
