<template>
  <div class="four-oh-four-container">
    <h1>404</h1>
    <p>Page Not Found</p>
    <nuxt-link
      to="/"
      class="button button--primary">Back Home</nuxt-link>
  </div>
</template>

<script>
export default {
  props: {},
  data: () => ({})
}
</script>

<style lang="scss" scoped>
.four-oh-four-container {
  margin: 0 auto;
  padding: 100px 45px;
  text-align: center;

  h1 {
    font-size: 64px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  p {
    font-size: 28px;
    margin-bottom: 45px;
  }
}
</style>
