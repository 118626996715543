export const state = () => ({
  cardInfo: null
})

export const mutations = {
  ADD_CARD_INFO (state, payload) {
    state.cardInfo = payload
  }
}

export const getters = {
  cardInfo: state => state.cardInfo
}
