<template>
  <div>
    <section id="header__help-bar">
      <p>Free U.S. Ground Shipping • 30-Day Satisfaction Guarantee • 2-Year Warranty</p>
    </section>

    <header id="main-header">
      <div id="logo">
        <nuxt-link to="/">
          <img src="/img/aquabed-logo.svg">
        </nuxt-link>
      </div>
      <main-nav/>
    </header>

    <div class="top-divider-line"></div>
  </div>
</template>

<script>
import MainNav from '~/components/MainNav'
export default {
  components: {
    MainNav
  }
}
</script>

<style lang="scss" scoped>
#header__help-bar {
  width: 100%;
  margin: 0 auto;
  padding: 12px 35px;
  text-align: center;
  background-color: $aqua-blue;

  p {
    color: #fff;
    margin: 0;
    font-size: 15px;
    font-weight: normal;
  }

  a {
    color: #fff;
  }

  @media screen and (max-width: 850px) {
    padding: 9px 35px;

    p {
      font-size: 14px;
    }
  }
}

/* === Logo === */
#logo {
  margin: auto 0;
  padding: 0;
  line-height: 1em;
  width: 200px;
  vertical-align: middle;

  img {
    width: 100%;
    display: block;
  }

  @media screen and (max-width: 850px) {
    width: 198px;
    padding: 0 0;
  }

  @media screen and (max-width: 450px) {
    width: 145px;
  }
}

#main-header {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 60px;

  @media screen and (max-width: 991px) {
    padding: 15px 30px;
  }
}

.top-divider-line {
  width: 100%;
  min-height: 1px;
  background-color: $grey;
}
</style>
